import React,{useState} from 'react';
import PageNav from './PageNav';
import { updateFollowup } from '../services/services';
import useForm from '../utilities/useForm';

const Followlist = (props) => {
	const eid = props.location.state.eid;
	const client = props.location.state.client;
	const [loading, setLoader] = useState(false);
	let userid = JSON.parse(localStorage.getItem('userid'));

	const [value, inputChange] = useForm({
		ftype: '',
		fdate: '',
		remark: '',
		orderStatus: '',
		nextfdate: '',
		nextftype: '',
	});

	const updateFolloe = (event) => {
		setLoader(true);
		event.preventDefault();
		event.persist();
		const data = new FormData();
		data.append('ftype', value.ftype);
		data.append('fdate', value.fdate);
		data.append('remark', value.remark);
		data.append('orderStatus', value.orderStatus);
		data.append('nextfdate', value.nextfdate);
		data.append('nextftype', value.nextftype);
		data.append('id', userid);
		data.append('eid', eid);
		updateFollowup(data)
			.then((res) => {
				if (res.data.response === 'success') {
					setLoader(false);
					alert('Success');
					props.history.push('/');
				} else {
					alert('try again');
				}
			})
			.catch((err) => err);
	};
	return (
		<div>
			{loading
				? [
						<div className="loader">
							<i class="las la-spinner"></i>
						</div>,
				  ]
				: null}
		<div className="container-fluid padrem">
			<PageNav pagename="Followup" />
			<div className="container">
				<form onSubmit={updateFolloe}>
					<div className="form-group">
						<select
							name="ftype"
							className="form-control"
							value={value.ftype === '' ? '' : value.ftype}
							onChange={inputChange}
							required
						>
							<option value="" disabled style={{ display: 'none' }}>
								Followup Type
							</option>
							<option value="Demo">Demo</option>
							<option value="Followup">Followup</option>
							<option value="Installation">Installation</option>
						</select>
					</div>
					<div className="form-group">
						<small className="text-light">Client Name</small>
						<input type="text" className="form-control" value={client} readOnly />
					</div>
					<div className="form-group">
						<input
							type="date"
							className="form-control"
							name="fdate"
							value={value.fdate}
							onChange={inputChange}
							required
						/>
					</div>
					<div className="form-group">
						<textarea
							className="form-control"
							placeholder="Remark"
							name="remark"
							value={value.remark}
							onChange={inputChange}
						></textarea>
					</div>
					<div className="form-group">
						<select
							name="orderStatus"
							className="form-control"
							value={value.orderStatus === '' ? 1 : value.orderStatus}
							onChange={inputChange}
						>
							<option value="1" disabled style={{ display: 'none' }}>
								Order Status
							</option>
							<option value="100">100</option>
							<option value="95">95</option>
							<option value="90">90</option>
							<option value="75">75</option>
							<option value="60">60</option>
							<option value="50">50</option>
							<option value="40">40</option>
							<option value="25">25</option>
							<option value="10">10</option>
							<option value="0">0</option>
						</select>
					</div>
					<div className="form-check mb-2">
						<label className="text-light">Next Followup</label>
					</div>

					<div className="form-group">
						<input
							type="date"
							className="form-control"
							name="nextfdate"
							value={value.nextfdate}
							onChange={inputChange}
							required
						/>
					</div>
					<div className="form-group">
						<select
							name="nextftype"
							className="form-control"
							value={value.nextftype === '' ? 0 : value.nextftype}
							onChange={inputChange}
						>
							<option value="0" disabled style={{ display: 'none' }}>
								Followup Type
							</option>
							<option value="Demo">Demo</option>
							<option value="Followup[">Followup</option>
							<option value="Installation">Installation</option>
						</select>
					</div>

					<div className="form-group">
						<button className="btn-1">Save</button>
					</div>
				</form>
			</div>
		</div>
		</div>
	);
};

export default Followlist;
