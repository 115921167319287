import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PageNav from './PageNav';
import { current, deleteCurrent } from '../services/services';
const Current = () => {
	const [currentlist, setCurrentlist] = useState([]);
    const [ignore, setIgnore] = useState(false);
    let userid = JSON.parse(localStorage.getItem('userid'));
	useEffect(() => {
		if (!ignore) {
			loadData();
		}
		return () => {
			setIgnore(true);
		};
	});
	const loadData = () => {
		current(userid)
			.then((res) => {
				setCurrentlist(res.data.current);
			})
			.catch((err) => err);
	};
	let i = 1;
	const deleteList = (data) => {
		deleteCurrent(data).then((res) => {
			if (res.data.response === 'success') {
				loadData();
			}
		});
	};
	return (
		<div className="container-fluid padrem">
			<PageNav pagename="Current List" />
			<div className="container">
				<table className="table table-dark table-striped table-sm">
					<thead>
						<tr>
							<th>No</th>
							<th>Client Name</th>
							<th style={{"width" : "30%"}}>Next Date</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{currentlist.map((list) => (
							<tr key={list.enquiry_id}>
								<td>{i++}</td>
								<td>
									<Link to={{ pathname: '/enquiryinfo', state: { eid: list.enquiry_id } }}>
										{list.client_name}
									</Link>
								</td>

								<td>{list.next_followup_date}</td>
								<td>
									<i
										className="las la-trash"
										onClick={() => {
											if (window.confirm('Are you sure you wish to delete this item?'))
												deleteList(list.enquiry_id);
										}}
									></i>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default Current;
