import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PageNav from './PageNav';
import { enquiryFollowup } from '../services/services';
const EnquiryInfo = (props) => {
	const [enquiry, setEnquiry] = useState([]);
	const [followup, setFollowup] = useState([]);
	const [ignore, setIgnore] = useState(false);
	const eid = props.location.state.eid;
	let i = 1;
	useEffect(() => {
		if (!ignore) {
			loadData();
		}
		return () => {
			setIgnore(true);
		};
	});
	const loadData = () => {
		enquiryFollowup(eid)
			.then((res) => {
				setEnquiry(res.data.enquiry[0]);
				setFollowup(res.data.followup);
			})
			.catch((err) => err);
	};

	return (
		<div className="container-fluid padrem">
			<PageNav pagename="Enquiry Details" />
			<div className="container">
				<table className="table table-dark table-striped table-sm">
					<tbody>
						<tr>
							<th>Enquiry No</th>
							<td>{enquiry.enquiry_id}</td>
						</tr>
						<tr>
							<th>Enquiry Date</th>
							<td>{enquiry.edate}</td>
						</tr>
						<tr>
							<th>Client Name</th>
							<td>{enquiry.client_name}</td>
						</tr>
						<tr>
							<th>Address</th>
							<td>{enquiry.address}</td>
						</tr>
						<tr>
							<th>Area</th>
							<td>{enquiry.area_name}</td>
						</tr>
						<tr>
							<th>Contact Person</th>
							<td>{enquiry.contact_person}</td>
						</tr>
						<tr>
							<th>Phone 1</th>
							<td>{enquiry.phone1}</td>
						</tr>
						<tr>
							<th>Phone 2</th>
							<td>{enquiry.phone2}</td>
						</tr>
						<tr>
							<th>Product</th>
							<td>{enquiry.product_name}</td>
						</tr>
						<tr>
							<th>Order Status</th>
							<td>{enquiry.order_status}</td>
						</tr>
						<tr>
							<th>Next Date</th>
							<td>{enquiry.next_followup_date}</td>
						</tr>
						<tr>
							<th>Remark</th>
							<td>{enquiry.remark}</td>
						</tr>
					</tbody>
				</table>
				<div className="row mb-3">
					<div className="col-6">
						<Link to={{ pathname: '/followupdate', state: { eid: enquiry.enquiry_id,client:enquiry.client_name } }}>
							<button className="btn-1">Followup</button>
						</Link>
					</div>
					<div className="col-6">
						<button className="btn-1">Edit</button>
					</div>
				</div>
				<table className="table table-dark table-striped table-sm">
					<thead>
						<tr>
							<th>No</th>
							<th>Date</th>
							<th>Next Date</th>
							<th>Remark</th>
							<th>Order Status</th>
						</tr>
					</thead>
					<tbody>
						{followup.map((list) => (
							<tr key={list.followup_id}>
								<td>{i++}</td>
								<td>{list.followup_date}</td>
								<td>{list.next_followup_date}</td>
								<td>{list.remark}</td>
								<td>{list.order_status}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default EnquiryInfo;
