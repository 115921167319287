import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Home from './components/Home'
import Current from './components/Current'
import Upcoming from './components/Upcoming'
import Enquiry from './components/Enquiry'
import Login from './components/login'
import EnquiryInfo from './components/EnquiryInfo'
import Followupdate from './components/Followupdate'
function App() {
  return (
   <Router>
     <Route exact path="/" component={Home} />
     <Route exact path="/login" component={Login} />
     <Route exact path="/current" component={Current} />
     <Route exact path="/upcoming" component={Upcoming} />
     <Route exact path="/enquiry" component={Enquiry} />
     <Route exact path="/enquiryinfo" component={EnquiryInfo} />
     <Route exact path="/followupdate" component={Followupdate} />
     {/* <Route exact path="/followlist" render={() => (userid ? <Followlist /> : <Redirect to="/login" />)} /> */}
   </Router>
  );
}

export default App;
