import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import './App.css';
class Home extends Component {
	componentDidMount() {
		let userid = JSON.parse(localStorage.getItem('userid'));
		if (!userid) {
			this.props.history.push('/login');
		}
	}
	logOut = () => {
		localStorage.clear();
		this.props.history.push('/login');
	};
	render() {
		// if(this.state.homedata){console.log(this.state.lastexpense)}

		return (
			<div className="container-fluid">
				{/* income & expense button */}
				<div className="container">
					<div className="row">
						<div className="col-6 padrem">
							<Link to="/enquiry">
								<div className="card-1 m-2 bg-dark">
									<i className="las la-plus"></i>
									<p>New</p>
								</div>
							</Link>
						</div>
						<div className="col-6 padrem">
							<Link to="/current">
								<div className="card-1 m-2 bg-dark">
									<i className="lar la-clock"></i>
									<p>Current</p>
								</div>
							</Link>
						</div>
						<div className="col-6 padrem">
							<Link to="/upcoming">
								<div className="card-1 m-2 bg-dark">
									<i className="las la-hourglass-half"></i>
									<p>Upcoming</p>
								</div>
							</Link>
						</div>
						<div className="col-6 padrem">
							<Link to="/">
								<div className="card-1 m-2 bg-dark">
									<i className="las la-comments"></i>
									<p>Enquires</p>
								</div>
							</Link>
						</div>
						<div className="col-6 padrem">
							<div onClick={this.logOut}>
								<div className="card-1 m-2 bg-dark">
									<i className="las la-sign-out-alt"></i>
									<p>Logout</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<hr className="hr-1" />
			</div>
		);
	}
}

export default Home;
