import React from 'react';
import useForm from '../utilities/useForm'
import {userLogin} from '../services/services'
// import { history} from './history'
const Login = (props)=>{
    const [value,inputChange]=useForm({
        uname:'',
        password:'',
    })
    const userLogins=(e)=>{
        e.preventDefault()
        const data = new FormData();
		data.append('uname', value.uname);
		data.append('password', value.password);
        userLogin(data).then((res)=>{
            if(res.data.access==='success'){
                localStorage.setItem("userid", JSON.stringify(res.data.uid[0].user_id))
                // window.location.href("/home")
                props.history.push('/');
            }
            else{
                alert("inavalid")
            }
        })
    }
    return(
        <div className="container">
			<div className="login">
				<form onSubmit={userLogins}>
					<div className="form-group">
						<input
							type="text"
							className="form-control"
							placeholder="Username"
							name="uname"
							value={value.uname}
							onChange={inputChange}
							required
						/>
					</div>

					<div className="form-group">
						<input
							type="password"
							className="form-control"
							placeholder="Password"
							name="password"
							value={value.password}
							onChange={inputChange}
							required
						/>
					</div>
                    <div className="form-group">
						<button className="btn-1">Login</button>
					</div>
				</form>
			</div>
		</div>
    )
}
export default Login;