import http from'../http-common'
// login start
export function userLogin(data){
    return http.post('userLogin',data);
}
// login end
// Enquiry page starts
export function newEnquiryLoad(){
    return http.get('/newEnquiryLoad');
}
export function newEnquiry(data){
    return http.post('/newEnquiry',data);
}
export function enquiryDetails(data){
    return http.post('/enquiryDetails/'+data)
}

export function enquiryFollowup(data){
    return http.post('/enquiryFollowup/'+data)
}
// Enquiry page end
// Current page starts
export function current(id){
    return http.post('/current/'+id);
}
export function deleteCurrent(data){
    return http.post('/deleteCurrent/'+data)
}
// Current page end
// Upcoming page starts
export function upcoming(id){
    return http.post('/upcoming/'+id);
}
// Upcoming page end
// followup start
export function followUpList(data){
    return http.post('/followUpList/'+data)
}
export function updateFollowup(data){
    return http.post('/updateFollowup',data)
}
// followup end