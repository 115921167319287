import React, { useEffect, useState } from 'react';
import PageNav from './PageNav';
import useForm from '../utilities/useForm';
import { newEnquiryLoad, newEnquiry } from '../services/services';
const Enquiry = (props) => {
	const [value, inputChange] = useForm({
		userid: '',
		edate: '',
		clientName: '',
		address: '',
		area: '',
		cperson: '',
		ph1: '',
		ph2: '',
		productId: '',
		orderStatus: '',
		remark: '',
		nextfdate: '',
		ftype: '',
	});
	const [loading, setLoader] = useState(false);
	const [area, setArea] = useState([]);
	const [products, setProducts] = useState([]);
	let id = JSON.parse(localStorage.getItem('userid'));
	useEffect(() => {
		let ignore = false;
		// let userid = JSON.parse(localStorage.getItem('userid'));
		// if (!userid) {
		// 	alert(userid);
		// 	props.history.push('/login');
		// }
		newEnquiryLoad()
			.then((res) => {
				if (!ignore) {
					setArea(res.data.area);
					setProducts(res.data.products);
				}
			})
			.catch((err) => err);
		return () => {
			ignore = true;
		};
	}, []);

	const saveEnquiry = (event) => {
		setLoader(true);
		event.preventDefault();
		event.persist();
		const data = new FormData();
		data.append('userid', value.userid);
		data.append('edate', value.edate);
		data.append('clientName', value.clientName);
		data.append('address', value.address);
		data.append('area', value.area);
		data.append('cperson', value.cperson);
		data.append('ph1', value.ph1);
		data.append('ph2', value.ph2);
		data.append('productId', value.productId);
		data.append('orderStatus', value.orderStatus);
		data.append('remark', value.remark);
		data.append('nextfdate', value.nextfdate);
		data.append('ftype', value.ftype);
		data.append('id', id);
		newEnquiry(data)
			.then((res) => {
				if (res.data.response === 'success') {
					alert('Success');
					setLoader(false);
					props.history.push('/');
					event.target.reset();
				} else {
					alert('try again');
				}
			})
			.catch((err) => err);
	};

	return (
		<div>
			{loading
				? [
						<div className="loader">
							<i class="las la-spinner"></i>
						</div>,
				  ]
				: null}
			<div className="container-fluid padrem">
				<PageNav pagename="Enquiry" />
				<div className="container">
					<form autoComplete="off" onSubmit={saveEnquiry}>
						<div className="form-group">
							<input
								type="date"
								className="form-control"
								name="edate"
								value={value.edate}
								onChange={inputChange}
								required
							/>
						</div>
						<div className="form-group">
							<input
								type="text"
								className="form-control"
								placeholder="Client Name"
								name="clientName"
								value={value.clientName}
								onChange={inputChange}
								required
							/>
						</div>
						<div className="form-group">
							<textarea
								className="form-control"
								placeholder="Address"
								name="address"
								value={value.address}
								onChange={inputChange}
							></textarea>
						</div>
						<div className="form-group">
							<select
								name="area"
								className="form-control text-capitalize"
								value={value.area === '' ? 0 : value.area}
								onChange={inputChange}
								required
							>
								<option value="0" disabled style={{ display: 'none' }}>
									Select Area
								</option>
								{area.map((areas) => (
									<option key={areas.area_id} value={areas.area_id}>
										{areas.area_name}
									</option>
								))}
							</select>
						</div>
						<div className="form-group">
							<input
								type="text"
								className="form-control"
								placeholder="Contact Person"
								name="cperson"
								value={value.cperson}
								onChange={inputChange}
							/>
						</div>
						<div className="form-group">
							<input
								type="tel"
								className="form-control"
								placeholder="Phone 1"
								name="ph1"
								value={value.ph1}
								onChange={inputChange}
							/>
						</div>
						<div className="form-group">
							<input
								type="tel"
								className="form-control"
								placeholder="Phone 2"
								name="ph2"
								value={value.ph2}
								onChange={inputChange}
							/>
						</div>
						<div className="form-group">
							<select
								name="productId"
								className="form-control text-capitalize"
								value={value.productId === '' ? '' : value.productId}
								onChange={inputChange}
								required
							>
								<option value="" disabled style={{ display: 'none' }}>
									Select Product
								</option>
								{products.map((product) => (
									<option key={product.product_id} value={product.product_id}>
										{product.product_name}
									</option>
								))}
							</select>
						</div>
						<div className="form-group">
							<select
								name="orderStatus"
								className="form-control"
								value={value.orderStatus === '' ? 1 : value.orderStatus}
								onChange={inputChange}
							>
								<option value="1" disabled style={{ display: 'none' }}>
									Order Status
								</option>
								<option value="100">100</option>
								<option value="95">95</option>
								<option value="90">90</option>
								<option value="75">75</option>
								<option value="60">60</option>
								<option value="50">50</option>
								<option value="40">40</option>
								<option value="25">25</option>
								<option value="10">10</option>
								<option value="0">0</option>
							</select>
						</div>
						<div className="form-group">
							<textarea
								className="form-control"
								placeholder="Remark"
								name="remark"
								value={value.remark}
								onChange={inputChange}
							></textarea>
						</div>
						<div className="form-check mb-2">
							<label className="text-light">Next Followup</label>
						</div>
						<div className="form-group">
							<input
								type="date"
								className="form-control"
								name="nextfdate"
								value={value.nextfdate}
								onChange={inputChange}
								required
							/>
						</div>
						<div className="form-group">
							<select
								name="ftype"
								className="form-control"
								value={value.ftype === '' ? 0 : value.ftype}
								onChange={inputChange}
							>
								<option value="0" disabled style={{ display: 'none' }}>
									Followup Type
								</option>
								<option value="Demo">Demo</option>
								<option value="Followup">Followup</option>
								<option value="Installation">Installation</option>
							</select>
						</div>
						,
						<div className="form-group">
							<button className="btn-1">Save</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Enquiry;
